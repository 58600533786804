<template>
  <div class="select-new">
    <template v-if="itemsList && itemsList.length">
      <v-text-field
        v-model="find"
        :label="label"
        type="text"
        class="field--new"
        :placeholder="label"
        outlined
        @keydown="() => active = true"
        @click="() => active = !active"
      />
      <v-icon class="select-new__icon" @click="() => active = !active">
        mdi-menu-down
      </v-icon>
      <ul
        v-show="active"
        ref="selectOwner"
        class="select-owner__list select-vuex__list"
      >
        <li
          v-for="(item, index) in filters()"
          :key="item[itemValue]"
          class="select-owner-list__item"
          :class="{'select-owner-list__item--hover': index === hover}"
          @click="setState(item.index)"
        >
          {{ item[itemText] }}
        </li>
      </ul>
    </template>
    <v-select
      v-else
      :label="label"
      :placeholder="label"
      class="field--new field--new_disabled"
      disabled
      outlined
    />
  </div>
</template>
<script>
export default {
  name: 'SelectVuex',
  props: {
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default () {
        return []
      }
    },
    itemText: {
      type: String,
      default: 'name'
    },
    itemValue: {
      type: String,
      default: 'id'
    },
    make: {
      type: Function,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      active: false,
      hover: 0,
      state: null,
      find: '',
      itemsList: null
    }
  },
  mounted () {
    this.itemsList = this.items.map((item, index) => ({ index, ...item }))
    document.body.addEventListener('keydown', (e) => {
      if (this.search(e.target)) {
        if (e.key === 'Tab') {
          this.active = false
        }
        if (e.key === 'Backspace' && this.state !== null) {
          this.state = null
          this.find = ''
        }
        if (e.key === 'ArrowUp' && this.hover > 0) {
          this.hover--
        }
        if (e.key === 'ArrowDown' && this.hover < this.items.length - 1) {
          this.hover++
        }
        if (e.key === 'Enter') {
          this.setState(this.filters()[this.hover].index)
          this.active = false
        }
      }
    })
    document.body.addEventListener('click', (e) => {
      if (!this.search(e.target)) {
        this.active = false
      }
    })
  },
  updated () {
    if ((!this.itemsList || !this.itemsList.length) && this.items && this.items.length) {
      this.itemsList = this.items.map((item, index) => ({ index, ...item }))
    }
  },
  methods: {
    setState (index) {
      this.state = index
      this.find = this.itemsList[index][this.itemText]
      this.active = false
      this.make(this.name, this.itemsList[index][this.itemValue])
    },
    search (node) {
      while (node && node !== this.$el) {
        node = node.parentElement
      }
      return node
    },
    filters () {
      return (this.state !== null && this.itemsList[this.state][this.itemText]) ? this.itemsList : this.itemsList
        .filter(str => str[this.itemText].trim() === '' || str[this.itemText].toLowerCase()
          .includes(this.find.toLowerCase()))
    }
  }
}
</script>
<style>
  .select-new {
    position: relative;
    color: #6d7e9c;
    margin-right: 8px;
  }

  .select-new .v-input__slot {
    margin-bottom: 0;
  }

  .field--new.v-input input {
    color: #202847 !important;
  }

  .select-new__icon {
    cursor: pointer;
    position: absolute !important;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .select-vuex__list {
    background-color: #fff;
    position: absolute;
    list-style-type: none;
    z-index: 100;
    border: 1px solid #d8dde6;
    border-radius: 3px;
    width: 100%;
    padding-left: 0 !important;
    max-height: 170px;
    overflow-y: scroll;
  }

  .select-owner-list__item {
    cursor: pointer;
    padding-left: 16px;
    padding-right: 35px;
    white-space: nowrap;
  }

  .select-owner-list__item:hover,
  .select-owner-list__item--hover {
    background: #e0e0e6;
    color: #000;
  }
</style>
