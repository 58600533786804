<template>
  <v-col cols="12" md="4">
    <v-card id="years" />
  </v-col>
</template>
<script>
const HEIGHT = 450
const WIDTH = 350
const MARGIN = 45
const Y = 26000
const START_X = 2013
const FINISH_X = 2021
const X_AXIS_LENGTH = WIDTH - 2 * MARGIN
const Y_AXIS_LENGTH = HEIGHT - 2 * MARGIN
export default {
  name: 'DrawYears',
  mounted () {
    this.createVisualYears()
  },
  methods: {
    createVisualYears () {
      const svg = this.$d3.select('#years').append('svg')
        .attr('class', 'axis')
        .attr('width', WIDTH)
        .attr('height', HEIGHT)

      const scaleX = this.$d3.scaleLinear()
        .domain([START_X, FINISH_X])
        .rangeRound([0, X_AXIS_LENGTH])

      const scaleY = this.$d3.scaleLinear()
        .domain([Y, 0])
        .range([0, Y_AXIS_LENGTH])

      const xAxis = this.$d3.axisBottom(scaleX)
      const yAxis = this.$d3.axisLeft(scaleY)

      svg.append('g')
        .attr('class', 'x-axis')
        .attr('transform',
          'translate(' + MARGIN + ',' + (HEIGHT - MARGIN) + ')')
        .call(xAxis)

      svg.append('g')
        .attr('class', 'y-axis')
        .attr('transform',
          'translate(' + MARGIN + ',' + MARGIN + ')')
        .call(yAxis)

      this.$d3.selectAll('g.x-axis g.tick')
        .append('line')
        .classed('grid-line', true)
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', 0)
        .attr('y2', -(Y_AXIS_LENGTH))

      this.$d3.selectAll('g.y-axis g.tick')
        .append('line')
        .classed('grid-line', true)
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', X_AXIS_LENGTH)
        .attr('y2', 0)
    }
  }
}
</script>
