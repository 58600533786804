<template>
  <div class="mt-3">
    <v-row>
      <v-col cols="12" md="6">
        <div class="lines__name">
          <h3>Зависимость цены от пробега</h3>
        </div>
      </v-col>
    </v-row>
    <div id="price" />
  </div>
</template>
<script>
const WIDTH = 1260
const HEIGHT = 500
const MARGIN = 40
const X = 280000
const Y = 40000
const X_AXIS_LENGTH = WIDTH - 2 * MARGIN
const Y_AXIS_LENGTH = HEIGHT - 2 * MARGIN
export default {
  name: 'DrawLines',
  mounted () {
    this.createCanvas()
  },
  methods: {
    createCanvas () {
      const svg = this.$d3.select('#price').append('svg')
        .attr('class', 'axis')
        .attr('width', WIDTH)
        .attr('height', HEIGHT)
      this.scaleX = this.$d3.scaleLinear()
        .domain([0, X])
        .range([0, X_AXIS_LENGTH])

      this.scaleY = this.$d3.scaleLinear()
        .domain([Y, 0])
        .range([0, Y_AXIS_LENGTH])

      const xAxis = this.$d3.axisBottom(this.scaleX)
      const yAxis = this.$d3.axisLeft(this.scaleY)

      svg.append('g')
        .attr('class', 'x-axis')
        .attr('transform', // сдвиг оси вниз и вправо
          'translate(' + MARGIN + ',' + (HEIGHT - MARGIN) + ')')
        .call(xAxis)

      svg.append('g')
        .attr('class', 'y-axis')
        .attr('transform', // сдвиг оси вниз и вправо на MARGIN
          'translate(' + MARGIN + ',' + MARGIN + ')')
        .call(yAxis)

      this.$d3.selectAll('g.x-axis g.tick')
        .append('line')
        .classed('grid-line', true)
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', 0)
        .attr('y2', -(Y_AXIS_LENGTH))

      this.$d3.selectAll('g.y-axis g.tick')
        .append('line')
        .classed('grid-line', true)
        .attr('x1', 10)
        .attr('y1', 0)
        .attr('x2', X_AXIS_LENGTH)
        .attr('y2', 0)
    }
  }
}
</script>
<style>
  .lines__name {
    margin-left: 90px;
  }
</style>
