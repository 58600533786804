import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import d3 from '@/plugins/d3' // path to vuetify export

Vue.config.productionTip = false

new Vue({
  vuetify,
  d3,
  render: h => h(App),
}).$mount('#app')
