<template>
  <div class="liquidity__item">
    <div class="liquidity-marker" :style="{'background-color': colors[marker]}">
      {{ marker }}
    </div>
    <div class="liquidity__name">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'LiquidityMarker',
  props: {
    marker: {
      type: String,
      default: 'F'
    }
  },
  data () {
    return {
      colors: {
        A: 'rgb(41, 212, 34)',
        B: '#d8f801',
        C: '#f8e700',
        D: '#f89e00',
        E: '#fc5600',
        F: '#fe0d01',
        '?': '#3F51B5'
      }
    }
  }
}
</script>
<style scoped>
  .liquidity__name {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 40px;
    margin-left: 20px;
    font-size: 1.1em;
  }

  .liquidity-marker {
    color: #fff;
    font-size: 5em;
    width: 138px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 138px;
    margin-left: 10px;
  }

  .liquidity__item {
    display: flex;
    align-items: center;
    height: calc(100% / 3);
  }
</style>
