<template>
  <div class="main-page px-1 pb-2">
    <header>
      <div>
        Метрика
      </div>
    </header>
    <main class="mb-5" data-app>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Выбрать транспорт
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col md="4">
                <select-vuex
                  v-if="marks"
                  name="make_id"
                  :make="get_id"
                  :items="marks"
                  label="Марка"
                  item-text="name"
                  item-value="id"
                  :disabled="!(marks && marks.length)"
                />
                <select-vuex label="Марка" v-else />
              </v-col>
              <v-col md="4">
                <select-vuex
                  v-if="models.length"
                  name="model_id"
                  :make="get_id"
                  :key="models.length"
                  :items="models"
                  label="Модель"
                  item-text="name"
                  item-value="id"
                  :disabled="!(make_id && models && models.length)"
                />
                <v-text-field v-else class="field--new" outlined :disabled="true" label="Модель"/>
              </v-col>
              <v-col md="4">
                <select-vuex
                  v-if="generations.length"
                  name="generation_id"
                  :make="get_id"
                  :key="generations.length * models.length"
                  :items="generations"
                  class="field--new"
                  label="Поколение"
                  item-text="name"
                  item-value="id"
                  :disabled="!(model_id && generations && generations.length)"
                />
                <v-text-field v-else class="field--new" outlined :disabled="true" label="Поколение"/>
              </v-col>
              <v-col md="4">
                <select-vuex
                  v-if="tank.length"
                  name="tank_id"
                  :make="get_id"
                  :items="tank"
                  class="field--new"
                  label="Двигатель"
                  item-text="text"
                  item-value="key"
                  :disabled="!(model_id && generations && generations.length)"
                />
              </v-col>
              <v-col md="4">
                <select-vuex
                  v-if="transmission.length"
                  name="transmission_id"
                  :make="get_id"
                  :items="transmission"
                  class="field--new"
                  label="Коробка"
                  item-text="text"
                  item-value="key"
                />
              </v-col>
              <v-col md="4">
                <v-text-field
                  v-model="mileage"
                  class="field--new"
                  label="Пробег"
                  outlined
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <v-select
                  v-model="count"
                  :items="counts"
                  item-text="text"
                  item-value="value"
                  label="Отображение линий"
                  outlined
                />
              </v-col>
              <v-col md="6">
                <div class="button__block">
                  <button
                    :disabled="isDisabled()"
                    class="indigo"
                    :class="{ 'button-disabled': isDisabled() }"
                    @click="getVehicle"
                  >
                    Рассчитать
                  </button>
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </main>
    <v-row class="liquidity__main">
      <v-col cols="12" md="4">
        <v-card class="liquidity">
          <div class="liquidity-price">
            {{ price || 'Цена' }}$
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="liquidity__items">
          <liquidity-marker :marker="liquidity ? liquidity.count_marker : '?'">
            <p>Количество <span>{{ liquidity && liquidity.count }}</span></p>
            <p>Нами продано <span>{{ liquidity && Math.ceil(liquidity.count * 0.05) }}</span></p>
          </liquidity-marker>
          <liquidity-marker :marker="liquidity ? liquidity.days_marker : '?'">
            <p>Среднее дней <span>{{ liquidity && liquidity.days }}</span></p>
          </liquidity-marker>
          <liquidity-marker v-if="graph" :marker="liquidity ? liquidity.views_marker : '?'">
            <p>Среднее просмотров <span>{{ liquidity && liquidity.views }}</span></p>
          </liquidity-marker>
        </v-card>
      </v-col>
      <draw-years v-if="f && !isLoading && items.length" :vehicles="items"/>
      <draw-years-loading v-else />
    </v-row>
    <v-card>
      <template v-if="graph">
        <draw-lines v-if="f && !isLoading && items.length" :vehicles="items" :get-approx="f"/>
        <draw-lines-loading v-else />
      </template>
    </v-card>
    <div v-if="timer" class="notification">
      {{ notifyInfo }}
    </div>
    <div class="loading" v-show="isLoading">
      <v-progress-circular
        :size="100"
        color="primary"
        indeterminate
      />
    </div>
  </div>
</template>
<script>
import axios from "axios";
import LiquidityMarker from './components/LiquidityMarker.vue';
import SelectVuex from './components/SelectVuex.vue';
import DrawLines from './components/DrawLines.vue';
import DrawLinesLoading from './components/DrawLinesLoading.vue';
import DrawYears from './components/DrawYears.vue';
import DrawYearsLoading from './components/DrawYearsLoading.vue';
const apiUrl = '/api/predict'
export default {
  components: {LiquidityMarker, DrawYearsLoading, DrawYears, DrawLinesLoading, DrawLines, SelectVuex},
  data () {
    return {
      graph: false,
      count: 0,
      counts: [
        { value: null, text: 'За всё время' },
        { value: null, text: 'За три месяца' },
        { value: null, text: 'За шесть месяцев' },
        { value: null, text: 'За год' }
      ],
      notifyInfo: '',
      isLoading: true,
      name: 'VehicleMetric',
      items: [],
      svg: null,
      brand: 204,
      model: 2512,
      generation: null,
      liquidity: null,
      vehicle: null,
      price: 0,
      mileage: 0,
      make_id: null,
      generation_id: null,
      model_id: null,
      marks: null,
      models: [],
      generations: [],
      transmission_id: null,
      f: null,
      transmission: [
        {text: "все", key: null},
        {text: "автомат", key: 'auto'},
        {text: "вариатор", key: 'variator'},
        {text: "механическая", key: 'manual'},
        {text: "робот", key: 'robot'},
      ],
      timer: null,
      tank_id: null,
      tank: [
        {text: "всё", key: null},
        {text: "бензин", key: 'petrol'},
        {text: "газ", key: 'gas'},
        {text: "газ, бензин", key: 'gas_petrol'},
        {text: "гибрид", key: 'hybrid'},
        {text: "гибрид, бензин", key: 'hybrid_petrol'},
        {text: "гибрид, дизель", key: 'hybrid_diesel'},
        {text: "дизель", key: 'diesel'},
        {text: "электро", key: 'electro'},
      ]
    }
  },
  mounted () {
    this.getMarks()
    this.creteCounts()
    this.graph = this.getState()
  },
  watch: {
    make_id (value) {
      this.model_id = null
      if (value) {
        this.getModels()
      }
    },
    model_id (value) {
      this.generation_id = null
      if (value) {
        this.getGenerations()
      }
    },
    generation_id () {
      this.tank_id = null
    },
    tank_id () {
      this.transmission_id = null
    },
    transmission_id () {
      this.mileage = null
    }
  },
  methods: {
    get_id (name, $event) {
      this[name] = $event
      if ($event) {
        switch (name) {
          case 'make_id':
            this.model_id = null
            this.getModels()
            break
          case 'model_id':
            this.generation_id = null
            this.getGenerations()
            break
          case 'generation_id':
            this.tank_id = null
            break
          case 'tank_id':
            this.transmission_id = null
            break
          case 'transmission_id':
            this.mileage = null
            break
        }
      }
    },
    isDisabled () {
      return !(this.make_id && this.model_id && this.generation_id && this.mileage)
    },
    setPrice (func) {
      this.price = func(this.mileage).toFixed(0)
    },
    getState (state = 'graph') {
      const url = new URL(window.location.href)
      const search = url.search.split('=')
      if (search.length > 0) {
        return search[1] === state
      }
    },
    creteCounts () {
      const date = new Date()
      const months = [0, 3, 6, 12]
      const todayMonth = date.getMonth()
      months.forEach((item, index) => {
        if (item) {
          date.setMonth(todayMonth - item + 1)
          this.counts[index].value = `${this.addZero(date.getDate())}-${this.addZero(date.getMonth())}-${date.getFullYear()}`
        }
      })
      this.count = this.counts[1].value
    },
    addZero (x) {
      return (x < 10 ? '0' : '') + x
    },
    createUrl (type) {
      let url = `${apiUrl}/${type}/brand/${this.make_id}/model/${this.model_id}`
      let flag = false
      const parameters = {
        generation: this.generation_id,
        transmission: this.transmission_id,
        engine: this.tank_id,
        mileage: this.mileage,
        date_from: this.count
      }
      for (const key in parameters) {
        if (parameters[key]) {
          url += `${flag ? '&' : '?'}${key}=${parameters[key]}`
          flag = true
        }
      }
      return url
    },
    createTimer (message) {
      this.timer = true
      this.notifyInfo = message
      this.timer = setTimeout(() => {
        this.isLoading = false
        this.timer = false
      }, 3000)
    },
    getPosition (e) {
      let x = 0
      let y = 0
      if (!e) {
        e = window.event
      }
      if (e.pageX || e.pageY) {
        x = e.pageX
        y = e.pageY
      } else if (e.clientX || e.clientY) {
        x = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft
        y = e.clientY + document.body.scrollTop + document.documentElement.scrollTop
      }
      return { x, y }
    },
    quickSelectMedian (items) {
      if (!items.length) {
        return 0
      }
      if (items.length % 2 === 1) {
        return this.quickSelect(items, items.length / 2)
      } else {
        return 0.5 * (
          this.quickSelect(items, items.length / 2 - 1) +
          this.quickSelect(items, items.length / 2)
        )
      }
    },
    randomInt (min, max) {
      const rand = min + Math.random() * (max + 1 - min)
      return Math.floor(rand)
    },
    last (items) {
      return items[items.length - 1]
    },
    getApprox(list) {
      let sumx = 0;
      let sumy = 0;
      let sumx2 = 0;
      let sumxy = 0;
      for (let i = 0; i < list.length; i++) {
        sumx += list[i].mileage;
        sumy += list[i].price;
        sumx2 += list[i].mileage * list[i].mileage;
        sumxy += list[i].mileage * list[i].price;
      }
      console.log('mileage, sumx', list.map(item => item.mileage))
      console.log('price, sumy', list.map(item => item.price))
      const a = list.length > 1 ? (list.length * sumxy - (sumx * sumy)) / (list.length * sumx2 - sumx * sumx) : 0
      const b = list.length > 1 ? (sumy - a * sumx) / list.length : list[0].price
      this.f = x => a * x + b
      this.setPrice(this.f)
      return this.f
    },
    quickSelect (items, k) {
      if (items.length === 1) {
        if (k === 0) {
          return null
        }
        return items[0]
      }
      const index = this.randomInt(0, items.length - 1)
      const pivot = items[index]
      const lows = []
      const highs = []
      const pivots = []
      for (const item of items) {
        if (item < pivot) {
          lows.push(item)
        }
        if (item > pivot) {
          highs.push(item)
        }
        if (item === pivot) {
          pivots.push(item)
        }
      }
      if (k < lows.length) {
        return this.quickSelect(lows, k)
      } else if (k < lows.length + pivots.length) {
        return pivots[0]
      } else {
        return this.quickSelect(highs, k - lows.length - pivots.length)
      }
    },
    filterMileage () {
      if (!this.items) {
        return
      }
      const maxDeviation = 2.5
      this.items.sort((x, y) => x.mileage - y.mileage)
      const medianIndex = Math.round((this.items.length - 1) / 2)
      this.medianMileage = this.items[medianIndex].mileage
      if (this.items.length > 10) {
        this.items = this.items.filter(item => this.medianMileage * maxDeviation > item.mileage)
      }
    },
    median (arr) {
      arr.sort((x, y) => x - y)
      const len = arr.length
      const middle = Math.round(len / 2)
      if (len % 2 === 0) {
        return arr[middle]
      }
      return Math.round((arr[middle] + arr[middle + 1]) / 2)
    },
    filterPrice () {
      if (!this.items) {
        return
      }
      const maxDeviation = 4
      this.medianPrice = this.median(this.items.map(item => item.price))
      if (this.items.length > 10) {
        this.items = this.items.filter(item =>
          this.medianPrice * maxDeviation > item.price &&
          this.medianPrice / maxDeviation < item.price
        )
      }
    },
    getVehicle () {
      const url = this.createUrl('adverts/items');
      this.isLoading = true
      axios.get(url)
        .then((response) => {
          this.items = response.data.adverts

          if (!this.items?.length) {
            this.createTimer('Данный вид транспорта отстуствует в базе данных')
          } else {
            console.log('before', this.items.length)
            this.filterPrice()
            console.log('filterPrice', this.items.length)
            this.filterMileage()
            this.getApprox(this.items)
            this.getGroupLiquidity()
          }
        }).catch((error) => {
          this.createTimer(error)
        }).finally(() => {
          this.isLoading = false
        })
    },
    getGroupLiquidity () {
      const url = this.createUrl('stats/liquidity');
      this.isLoading = true
      axios.get(url)
        .then((response) => {
          this.liquidity = response.data.group_liquidity
        }).catch((error) => {
          this.createTimer(error)
        }).finally(() => {
          this.isLoading = false
        })
    },
    getEstimatedPrice () {
      const url = this.createUrl('stats/price');
      this.isLoading = true
      axios.get(url)
        .then((response) => {
          this.price = response.data.estimated_price
        }).catch((error) => {
          this.createTimer(error)
        }).finally(() => {
          this.isLoading = false
        })
    },
    getMarks () {
      const url = '/api/dict/makes?per_page=1000'
      this.isLoading = true
      axios.get(url)
        .then(response => {
          this.marks = response.data.data
        }).catch((error) => {
          this.createTimer(error)
        }).finally(() => {
          this.isLoading = false
        })
    },
    getModels () {
      const url = `/api/dict/models?per_page=1000&make_id=${this.make_id}`
      this.isLoading = true
      axios.get(url)
        .then(response => {
          this.models = response.data.data
        }).catch((error) => {
          this.createTimer(error)
        }).finally(() => {
          this.isLoading = false
        })
    },
    getGenerations () {
      const url = `/api/dict/generations?per_page=1000&make_id=${this.make_id}&model_id=${this.model_id}`
      this.isLoading = true
      axios.get(url)
        .then(response => {
          this.generations = response.data.data.map(item => ({ ...item, name: (item.name.length ? item.name : 'I') + ` (${item.year_from}-${item.year_to})` }))
          this.generations.sort((a, b) => {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            return 0;
          })
        }).catch((error) => {
          this.createTimer(error)
        }).finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
<style>
  .mt-3 {
    margin-top: 25px;
  }

  .liquidity__items {
    height: 100%;
  }

  .button__block {
    display: flex;
    height: calc(100% - 8px);
  }

  .indigo {
    padding: 10px 20px;
    align-self: flex-end;
    color: #fff;
    background: #42b983;
    cursor: pointer;
    box-shadow: 0 0 2px 0 black;
    height: 100%;
    width: 100%;
  }

  .button-disabled {
    background: #ccc;
  }

  .field--new.v-input input,
  .field--new.v-input textarea {
    color: #6d7e9c !important;
  }

  .field--new.v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 39px;
  }

  .v-text-field .v-label {
    max-width: 100% !important;
  }

  .v-text-field .v-label--active {
    max-width: 133% !important;
  }

  .field--new.v-text-field--outlined .v-label {
    top: 10px;
  }

  .field--new.v-text-field--outlined .primary--text,
  .field--new.v-text-field--outlined .v-label--active {
    top: 18px;
  }

  .field--new .v-text-field__details {
    display: none;
  }

  .field--new > .v-input__control > .v-input__slot {
    opacity: 1;
    min-height: auto;
  }

  .field--new.v-input input,
  .field--new .v-select__selections {
    max-height: none;
    height: 39px;
  }

  .field--new .v-input__append-inner {
    margin-top: 9px;
  }

  .field--new .v-select__selections {
    align-items: flex-start;
  }

  .field--new .v-select__selection--comma {
    margin-top: 2px;
  }

  .field--new fieldset {
    border-color: #d8dde6;
  }

  .field--new .v-label {
    top: 10px;
  }

  .field--new.v-select .v-select__selection {
    color: #6d7e9c !important;
  }

  .field--new .v-input__append-inner {
    margin-top: 0 !important;
    height: 100% !important;
  }

  .field--new.v-text-field--outlined .primary--text {
    top: 50% !important;
  }

  .field--new .v-input__icon {
    height: 100%;
  }

  .field--new .v-icon {
    color: #6d7e9c !important;
  }

  .field--new .v-input__slot .v-label {
    color: #6d7e9c;
  }

  header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 60px;
    background: #42b983;
    box-shadow: 0 0 5px 0 black;
  }

  main {
    margin-top: 90px;
  }

  header div {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 1.2em;
    color: #fff;
    width: 1350px;
    margin: 0 auto;
    padding-left: 24px;
  }

  .v-text-field__details {
    display: none !important;
  }

  .mb-5 {
    margin-bottom: 25px;
  }

  .liquidity__main {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
  }

  .loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff99;
    z-index: 100000;
  }

  .container {
    margin: 20px 0;
  }

  .notification {
    z-index: 100000000;
    position: fixed;
    top: 50px;
    right: 50px;
    background: #da0000;
    width: 300px;
    padding: 20px;
    border-radius: 3px;
    color: #fff;
    font-size: 1.2em;
    box-shadow: 0 0 3px 0 black;
  }
  .main-page {
    width: 1350px;
    margin: 20px auto;
  }

  #years {
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    padding-right: 0px;
  }

  .default__vehicles {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
  }

  .liquidity-price {
    padding: 20px;
    font-size: 6em;
    margin: 0;
    line-height: 1em;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .liquidity {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .axis path,
  .axis line {
    fill: none;
    stroke: #333;
  }

  .axis .grid-line {
    stroke: #000;
    stroke-opacity: 0.2;
  }

  .tollip p {
    margin: 0;
  }

  #price {
    position: relative;
  }

  .tollip {
    background-color: #fff;
    padding: 10px;
    position: absolute;
    z-index: 1000;
    border: 2px solid #000;
    border-radius: 3px;
  }
</style>
