<template>
  <v-col cols="12" md="4">
    <div class="years__block">
      <div id="years" />
      <div class="years__price">Цена</div>
      <div class="years__value">Год</div>
    </div>
  </v-col>
</template>
<script>
const HEIGHT = 450
const WIDTH = 350
const MARGIN = 45
const X_AXIS_LENGTH = WIDTH - 2 * MARGIN
const Y_AXIS_LENGTH = HEIGHT - 2 * MARGIN
export default {
  name: 'DrawYears',
  props: {
    vehicles: {
      type: Array,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      items: this.vehicles
    }
  },
  mounted () {
    this.createVisualYears()
  },
  methods: {
    getMiddleCountYears () {
      const result = {}
      this.items.forEach((item) => {
        if (result[item.year]) {
          result[item.year].count++
          result[item.year].sumPrice += item.price
        } else {
          result[item.year] = { count: 1, sumPrice: item.price }
        }
      })
      for (const key in result) {
        result[key] = result[key].sumPrice / result[key].count
      }
      return result
    },
    getYears () {
      const data = this.getMiddleCountYears()
      const items = []
      for (const x in data) {
        items.push({ x, y: data[x] })
      }
      return items
    },
    maxY (items) {
      let max = -Infinity
      items.forEach((item) => {
        max = max > item.y ? max : item.y
      })
      return max
    },
    createVisualYears () {
      const data = this.getYears()
      const svg = this.$d3.select('#years').append('svg')
        .attr('class', 'axis')
        .attr('width', WIDTH)
        .attr('height', HEIGHT)

      const years = this.items.map(item => item.year)
      let distance = Math.max.apply(null, years) - Math.min.apply(null, years)
      distance = (distance < 9 ? (8 - distance) : 0)
      const scaleX = this.$d3.scaleLinear()
        .domain([Math.min.apply(null, years) - Math.floor(distance / 2), Math.max.apply(null, years) + Math.ceil(distance / 2)])
        .rangeRound([0, X_AXIS_LENGTH])
      const scaleY = this.$d3.scaleLinear()
        .domain([this.maxY(data) * 1.05, 0])
        .range([0, Y_AXIS_LENGTH])

      const xAxis = this.$d3.axisBottom(scaleX)
      const yAxis = this.$d3.axisLeft(scaleY)

      svg.append('g')
        .attr('class', 'x-axis')
        .attr('transform',
          'translate(' + MARGIN + ',' + (HEIGHT - MARGIN) + ')')
        .call(xAxis)

      svg.append('g')
        .attr('class', 'y-axis')
        .attr('transform',
          'translate(' + MARGIN + ',' + MARGIN + ')')
        .call(yAxis)

      this.$d3.selectAll('g.x-axis g.tick')
        .append('line')
        .classed('grid-line', true)
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', 0)
        .attr('y2', -(Y_AXIS_LENGTH))

      this.$d3.selectAll('g.y-axis g.tick')
        .append('line')
        .classed('grid-line', true)
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', X_AXIS_LENGTH)
        .attr('y2', 0)

      const line = this.$d3.line()
        .x(d => scaleX(d.x) + MARGIN)
        .y(d => scaleY(d.y) + MARGIN)

      svg.append('g').append('path')
        .attr('d', line(data))
        .style('stroke', 'blue')
        .style('stroke-width', 3)

      const year = 2017
      let y = 0
      const vehicleArea = [
        { x: year, y: 0 }
      ].concat(data.filter((item) => {
        if (+item.x === 2017) {
          y = item.y
          return true
        }
        return false
      }))
      vehicleArea.push(
        { x: year + 1, y }
      )
      vehicleArea.push(
        { x: year + 1, y: 0 }
      )
    }
  }
}
</script>
<style>
  .years__block {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
    position: relative;
    flex-direction: column-reverse;
  }

  .years__price {
    position: absolute;
    left: 10px;
    transform: rotate(-90deg);
    font-size: 1.5em;
  }

  .years__value {
    position: absolute;
    bottom: 10px;
    font-size: 1.5em;
  }
</style>
