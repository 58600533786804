<template>
  <div class="mt-3">
    <v-row class="row--legend">
      <v-col cols="12" md="6">
        <div class="lines__name">
          <h3>Зависимость цены от пробега</h3>
        </div>
      </v-col>
    </v-row>
    <div id="price">
      <div v-if="tollip" class="tollip" :style="{top: tollipTop + 'px', left: tollipLeft + 'px'}">
        <p>Цена {{ mousePrice }} <b>$</b></p>
        <p>Пробег {{ mouseMileage }} <b>км</b></p>
      </div>
      <div class="price__mileage">
        Пробег (км)
      </div>
      <div class="price__price">
        Цена ($)
      </div>
    </div>
  </div>
</template>
<script>
const WIDTH = 1260
const HEIGHT = 500
const MARGIN = 50
const X_AXIS_LENGTH = WIDTH - 2 * MARGIN
const Y_AXIS_LENGTH = HEIGHT - 2 * MARGIN
export default {
  name: 'DrawLines',
  props: {
    vehicles: {
      type: Array,
      default () {
        return {}
      }
    },
    getApprox: {
      type: Function,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      mousePrice: null,
      mouseMileage: null,
      index: 0,
      medianMileage: null,
      maxPrice: null,
      medianPrice: null,
      svg: null,
      scaleX: null,
      scaleY: null,
      tollipTop: null,
      tollip: false,
      tollipLeft: null,
      items: this.vehicles,
      lines: {},
      dots: [],
      dotHover: null,
      f: () => {}
    }
  },
  computed: {
    maxMileage () {
      return this.last(this.items).mileage
    }
  },
  watch: {
    count (value) {
      for (const key in this.lines) {
        this.lines[key].style.display = value === 'all' ? 'block' : 'none'
      }
      if (this.lines[value]) {
        this.lines[value].style.display = 'block'
      }
      this.dots.forEach((dot) => {
        if (value === 'all' || dot.classList.contains(`dot-${value}`)) {
          dot.style.display = 'block'
        } else {
          dot.style.display = 'none'
        }
      })
    }
  },
  mounted () {
    this.createCanvas()
    const arr = this.items
    const shiftItem = arr[0]
    shiftItem.mileage = 0
    arr.unshift(shiftItem)
    this.lines['main'] = this.drawLine('#f00')
    this.createDots(arr)
  },
  methods: {
    last (items) {
      return items[items.length - 1]
    },
    getPublishedAtRight (items, time) {
      const currentDate = new Date()
      return items.filter((item) => {
        const date = new Date(item.published_at)
        return (+date + time < +currentDate)
      })
    },
    getPublishedAt (items, time) {
      const currentDate = new Date()
      return items.filter((item) => {
        const date = new Date(item.published_at)
        return (+date + time >= +currentDate)
      })
    },
    drawElips (a, b, L = false) {
      const elips = L ? (x, sign) => {
        const A = 1 / (b ** 2)
        const B = -2 * Math.cos(L) * x / (a * b)
        const C = (x / a) ** 2 - Math.sin(L) ** 2
        const D = B ** 2 - 4 * A * C
        return (sign * Math.sqrt(D) - B) / (2 * A)
      } : (x, sign) => sign * b * Math.sqrt(1 - (x / a) ** 2)
      const result = []
      const start = -a
      const finish = a
      const step = 1
      let sign = 1
      let x = start
      while (x >= start) {
        if (x >= finish) {
          sign = -sign
        }
        const y = elips(x, sign)
        const last = result[result.length - 1]
        if (isNaN(y) && !last) {
          x += sign * step
          continue
        }
        result.push({ x, y: isNaN(y) ? last.y : y })
        x += sign * step
      }
      return result
    },
    getCoords (elem) {
      const box = elem.getBoundingClientRect()
      return {
        top: box.top + pageYOffset,
        left: box.left + pageXOffset
      }
    },
    createRawData (items) {
      const rawData = []
      items.forEach((item) => {
        rawData.push({ y: item.price, x: item.mileage })
      })
      return rawData
    },
    getPosition (e) {
      let x = 0
      let y = 0

      if (!e) {
        e = window.event
      }

      if (e.pageX || e.pageY) {
        x = e.pageX
        y = e.pageY
      } else if (e.clientX || e.clientY) {
        x = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft
        y = e.clientY + document.body.scrollTop + document.documentElement.scrollTop
      }

      return { x, y }
    },
    createDots (items) {
      const rawData = this.createRawData(items)
      const { top, left } = this.getCoords(document.getElementById('price'))
      const xPrice = left
      const yPrice = top
      this.svg._groups[0][0].onmousemove = (event) => {
        const { x, y } = this.getPosition(event)
        this.tollip = true
        this.tollipTop = y - yPrice
        this.tollipLeft = x - xPrice
        const classList = event.target.classList
        if (this.dotHover) {
          this.dotHover.classList.remove('dot-hover')
          this.dotHover = null
        }
        if (classList.contains('dots')) {
          this.dotHover = event.target
          this.mousePrice = this.dotHover.dataset.price
          this.mouseMileage = this.dotHover.dataset.mileage
          this.dotHover.classList.add('dot-hover')
        } else if (classList.contains('line')) {
          const { layerX } = event
          let isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
          const x = (this.maxMileage - 100000) * (layerX - (isChrome ? 51 : 140)) / 1157 + 100000
          const y = this.getApprox(x.toFixed(0))
          this.mouseMileage = Math.round(x)
          this.mousePrice = Math.round(y)
        } else {
          this.tollip = false
        }
      }
      const dots = this.svg.selectAll('.dot')
        .data(rawData)
        .enter().append('circle')
        .attr('class', `dots`)
        .attr('r', 2.5)
        .attr('cx', d => this.scaleX(d.x) + MARGIN)
        .attr('cy', d => this.scaleY(d.y) + MARGIN)
        .attr('data-price', d => d.y)
        .attr('data-mileage', d => d.x)
      this.dots = this.dots.concat(dots._groups[0])
    },
    getMax (items) {
      let max = -Infinity
      for (const item of items) {
        max = item > max ? item : max
      }
      return max
    },
    createCanvas () {
      this.svg = this.$d3.select('#price').append('svg')
        .attr('class', 'axis')
        .attr('width', WIDTH)
        .attr('height', HEIGHT)
      this.scaleX = this.$d3.scaleLinear()
        .domain([100000, this.maxMileage])
        .range([0, X_AXIS_LENGTH])

      const maxPrice = this.getMax(this.items.map(item => item.price))
      this.scaleY = this.$d3.scaleLinear()
        .domain([maxPrice * 1.05, 0])
        .range([0, Y_AXIS_LENGTH])

      const xAxis = this.$d3.axisBottom(this.scaleX)
      const yAxis = this.$d3.axisLeft(this.scaleY)

      this.svg.append('g')
        .attr('class', 'x-axis')
        .attr('transform', // сдвиг оси вниз и вправо
          'translate(' + MARGIN + ',' + (HEIGHT - MARGIN) + ')')
        .call(xAxis)

      this.svg.append('g')
        .attr('class', 'y-axis')
        .attr('transform', // сдвиг оси вниз и вправо на MARGIN
          'translate(' + MARGIN + ',' + MARGIN + ')')
        .call(yAxis)

      this.$d3.selectAll('g.x-axis g.tick')
        .append('line')
        .classed('grid-line', true)
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', 0)
        .attr('y2', -(Y_AXIS_LENGTH))

      this.$d3.selectAll('g.y-axis g.tick')
        .append('line')
        .classed('grid-line', true)
        .attr('x1', 10)
        .attr('y1', 0)
        .attr('x2', X_AXIS_LENGTH)
        .attr('y2', 0)
    },
    drawLine (color) {
      const line = this.$d3.line()
        .x(d => this.scaleX(d.x) + MARGIN)
        .y(d => this.scaleY(d.y) + MARGIN)
      const data = [
        {
          x: 100000,
          y: this.getApprox(100000)
        },
        {
          x: this.maxMileage,
          y: this.getApprox(this.maxMileage)
        }
      ]
      const item = this.svg.append('g').append('path')
        .attr('d', line(data))
        .attr('class', 'line')
        .style('stroke', color)
        .style('stroke-width', 3)
      return item._groups[0][0]
    },
    middleAngle (items) {
      let sum = 0
      const len = items.length - 1
      for (let i = 0; i < len; i++) {
        const a = items[i].x - items[i + 1].x
        const b = items[i].y - items[i + 1].y
        sum += Math.PI / 2 - Math.atan(a / b)
      }
      return sum / len
    },
    createLine (items) {
      if (!items.length) {
        console.log('createLine - Массив пуст')
        return
      }
      const sizeStep = Math.round(items.length ** (0.8))
      const middlePrice = i => this.middleValue(items, sizeStep, 'price', i)
      const middleMileage = i => this.middleValue(items, sizeStep, 'mileage', i)
      const linePixels = [{
        x: items[0].mileage,
        y: Math.sqrt(items[0].price * middlePrice(0))
      }]
      for (let i = 1; i < items.length; i += sizeStep) {
        linePixels.push({
          x: middleMileage(i),
          y: middlePrice(i)
        })
      }
      linePixels.push({
        x: this.maxMileage,
        y: Math.sqrt(this.last(linePixels).y * this.last(this.items).price)
      })
      return linePixels
    },
    middleValue (items, sizeStep, key, i) {
      const count = i + sizeStep < items.length ? sizeStep : items.length - i
      let sum = 0
      for (let j = 0; j < sizeStep && i + j < items.length; j++) {
        sum += items[i + j][key] / count
      }
      return sum
    }
  }
}
</script>
<style>
  .price__price {
    position: absolute;
    left: 0;
    transform: rotate(-90deg);
    top: 47%;
    font-size: 1.3em;
  }
  .row--legend {
    width: 1230px;
    margin-left: 59px;
  }

  #price {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
  }

  #price .axis {
    align-self: flex-end;
  }

  .price__mileage {
    font-size: 1.5em;
    margin-bottom: 10px;
  }

  .legend {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
  }

  .legend__color {
    width: 20px;
    height: 20px;
    float: left;
    margin-right: 5px;
  }

  .legend__color_red {
    background-color: #ff0000;
  }

  .legend__color_green {
    background-color: #008000;
  }
  .legend__color_blue {
    background-color: #0000ff;
  }
  .legend__color_yellow {
    background-color: #ffff00;
  }
  .dots {
    stroke: grey;
    fill: grey;
  }

  .lines__name {
    margin-left: 90px;
  }
</style>
